/**
 * @author JuanFuent.es
 * @desc Imprime en consola del browser créditos
 * @param { String } Message
 */
export default class Credits {
    constructor(msg = "Lorem Credits") {
        this.msg = [
            `%c ${msg} `,
            'background: orange; color: #FFF; padding: 5px 5px 5px 5px;'
        ]
        this.craft = [
            "%c 👨🏽‍💻 Developed by %c [https://juanfuent.es] ",
            'background: #a9dc76; color: #0f0c0d; padding: 5px 0;',
            'color: #FFF; background: #000; padding: 5px 5px 5px 5px;'
        ]
        this.roll()
    }
    
    roll() {
        console.log.apply(console, this.msg)
        console.log.apply(console, this.craft)
    }
}